<template>
  <div>
    <HeaderTrial class="d-lg-none" v-if="isPlayTrialModeMobile" />
    <div
      class="headerMobile"
      :class="{
        mt40: isPlayTrialModeMobile
      }"
    >
      <UIHeaderMobile
        :name="namePage"
        :ticket="{ orderId, currentRouteName: $router.history.current.name, ticketId }"
      />
      <div class="ml-auto myNavbarIconsRep" v-if="this.headerIcons == 'history'">
        <router-link class="pointer ml-2" to="/tai-khoan/lich-su-nap-tien/">
          <img src="@/assets/img/icon/icon_acc_history.svg" alt />
        </router-link>
      </div>
      <div class="ml-auto myNavbarIconsRep" v-if="this.headerIcons == 'historyWithdraw'">
        <router-link class="pointer ml-2" to="/tai-khoan/lich-su-giao-dich/">
          <img src="@/assets/img/icon/icon_acc_history.svg" alt />
        </router-link>
      </div>
      <div class="ml-auto myNavbarIconsRep" v-if="this.headerIcons == 'info'">
        <div class="pointer ml-2">
          <img
            @click="isOpenmodalTransaction = true"
            src="@/assets/img/icon/icon_info.svg"
            alt
            class="title-btn customIcon"
          />
        </div>
      </div>
      <div
        class="ml-auto myNavbarIconsRep"
        v-if="this.headerIcons == 'search' && userInfo.vip_data && userInfo.vip_data.is_shop"
      >
        <!-- <router-link class="pointer ml-2" to="/tai-khoan/tim-ve/">
          <img src="@/assets/img/icon_search.svg" alt />
        </router-link> -->
      </div>
      <!-- <div class="ml-auto myNavbarIconsRep" v-if="this.headerIcons == 'cart'">
        <NavbarIconsRep :isOnlyNotify="true" />
      </div> -->
    </div>
  </div>
</template>
<script>
import UIHeaderMobile from '@/components/UI/Header/UIHeaderMobile';
import HeaderTrial from '@/components/UI/Header/HeaderTrial';

// import NavbarIconsRep from './HeaderDesktop/NavbarIconsRep';
export default {
  components: {
    UIHeaderMobile,
    HeaderTrial
    // NavbarIconsRep
  },
  data() {
    return {
      ticketId: this.$route.params.ticketId,
      namePageProfile: '',
      orderId: ''
    };
  },
  created() {
    this.$bus.$off('updateTitleMobile', this.updateNamePage());
    this.$bus.$on('updateTitleMobile', ($event) => {
      this.updateNamePage($event);
    });
    this.$bus.$off('updateOrderIdMobile', this.updateOrderIdMobile());
    this.$bus.$on('updateOrderIdMobile', ($event) => {
      this.updateOrderIdMobile($event);
    });
  },
  computed: {
    namePage() {
      return this.$route.meta.name || this.namePageProfile;
    },
    headerIcons() {
      return this.$route.meta.icon || '';
    },
    isOpenmodalTransaction: {
      get() {
        return this.$store.getters.getterIsOpenModalTransactionResult;
      },
      set(newVal) {
        this.$store.commit('setIsOpenModalTransactionResult', newVal);
      }
    },
    userInfo() {
      return this.$store.getters.getterUserInfo;
    }
  },
  watch: {
    $route(to, from) {
      if (!to.params.ticketId) return (this.ticketId = '');
      if (to.params.ticketId != from.params.ticketId) {
        this.ticketId = to.params.ticketId;
      }
    }
  },
  methods: {
    updateNamePage(event) {
      if (!event) return;
      if (event.profile == 'profile') {
        this.namePageProfile = 'Kết quả ' + event.title || '';
      } else {
        this.namePageProfile = event.title;
      }
    },
    updateOrderIdMobile(event) {
      if (!event) return;
      this.orderId = event;
    }
  }
};
</script>
