<template>
  <CommonModal
    :title="`Hướng dẫn ${title}`"
    :isOpen="isOpenModalTutorial"
    @updateOpen="handleOpenModalTutorial"
    :fullHeight="true"
    :isHiddenFooter="true"
    :modalClass="'modalTutorial'"
    @handleShownModal="handleShown"
    @handleHide="isLoading = true"
  >
    <div>
      <div>
        <div>
          <div class="mb-4">
            <!-- <h4>{{ title }}</h4> -->
            <div v-if="!isLoading && imageUrl" class="d-flex justify-content-center">
              <img :src="imageUrl" width="160" height="126" class="img-109-86-mb" />
            </div>

            <content-placeholders v-else :rounded="true">
              <content-placeholders-img />
            </content-placeholders>
          </div>
          <b-tabs class="articleContentTab" fill>
            <b-tab title="Luật chơi" active>
              <div v-if="!isLoading && content && content.length > 0" class="container px-lg-3 px-0 pt-4">
                <div class="fullDescription" v-html="content"></div>
              </div>

              <content-placeholders v-else class="my-3">
                <content-placeholders-text :lines="3" />
              </content-placeholders>
            </b-tab>
            <b-tab title="Cách chơi">
              <div v-if="!isLoading && content2 && content.length > 0" class="container px-lg-3 px-0 py-4">
                <div class="fullDescription" v-html="content2"></div>
              </div>
              <content-placeholders v-else>
                <content-placeholders-text :lines="3" class="my-3" />
              </content-placeholders>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <!-- <div v-else class="d-flex justify-content-center align-items-center h-100">
        <b-spinner></b-spinner>
      </div> -->
    </div>
  </CommonModal>
</template>
<script>
import CommonModal from '../../CommonModal';
export default {
  components: { CommonModal },
  props: ['tutorialGame'],
  data() {
    return {
      content: undefined,
      content2: undefined,
      imageUrl: '',
      title: '',
      isLoading: true,
      isOpenModalTutorial: false,
      tag: ''
    };
  },
  computed: {
    timeWaitingArticle: {
      get() {
        return this.$store.getters.getterTimeWaitingArticle;
      },
      set(newVal) {
        this.$store.commit('setTimeWaitingArticle', newVal);
      }
    },
    getSlugGame() {
      if (!this.tutorialGame) return '';
      return this.tutorialGame.replace('/luat-choi/', '');
    }
  },
  methods: {
    handleOpenModalTutorial(value) {
      this.isOpenModalTutorial = value;
    },
    handleShown() {
      this.getArticleBySlug();
    },
    getArticleBySlug() {
      if (this.getSlugGame === this.tag) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;

      if (this.timeWaitingArticle) {
        clearTimeout(this.timeWaitingArticle);
      }
      this.timeWaitingArticle = setTimeout(() => {
        this.$store
          .dispatch('getArticleBySlug', this.getSlugGame)
          .then((resp) => {
            const { content, content2, title, image_url, tag } = resp;
            const splitTitle = title.split('');
            splitTitle[0] = splitTitle[0]?.toLowerCase() || '';
            this.title = splitTitle.join('');
            this.content = content;
            this.content2 = content2;
            this.imageUrl = image_url || undefined;
            this.tag = tag;
          })
          .catch(() => {
            // this.$router.push({ name: 'notFound' });
          })
          .finally(() => {
            this.isLoading = false;
          });
      }, 300);
    }
  }
};
</script>
