<template>
  <SubMainApp>
    <div :class="{ 'd-none': !isRoot(), 'd-lg-block': true }">
      <UIBanner
        :classLoading="'placeholders-banner-game'"
        v-if="!isOnlyMobile"
        :keyData="'w_account'"
        :classImage="'img-common'"
        carouselId="banner-profile"
      />
      <UIBanner v-if="isOnlyMobile" :keyData="'m_account'" :classImage="'img-common d-block d-lg-none'" />
    </div>
    <div
      class="container container-flex mb-lg-5 profileUserParent"
      id="scroll"
      :class="{ profileUserParentHome: $route.path === '/tai-khoan' }"
    >
      <ProfileSummary class="position-relative" />
      <div :class="{ 'profile-content': true, 'd-none': isRoot(), 'd-lg-block': true }">
        <transition>
          <router-view v-if="!isRoot()" :key="$route.name"></router-view>
          <profile v-else />
        </transition>
      </div>
    </div>
    <navbar-mobile class="d-block d-lg-none" v-if="$route.path == '/tai-khoan'" />
  </SubMainApp>
</template>

<script>
// import BannerProfile from '../components/Profile/BannerProfile';
import UIBanner from '../components/UI/Banner/UIBanner';
import SubMainApp from '../layouts/SubMainApp';
import ProfileSummary from '../components/Profile/partials/ProfileSummary.vue';
import Profile from '../components/Profile/Profile';
import NavbarMobile from '../components/NavbarMobile';
export default {
  layouts: 'Profile',
  components: {
    SubMainApp,
    ProfileSummary,
    Profile,
    NavbarMobile,
    UIBanner
  },
  data() {
    return {};
  },
  beforeCreate() {},
  created() {},
  mounted() {
    if (this.loggedIn) {
      this.refreshBalances();
    } else {
      this.$router.push('/');
    }
  },
  computed: {
    isLoadBalances() {
      return this.$store.getters.getterIsLoadBalances;
    },
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    },
    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    }
  },
  methods: {
    isRoot() {
      return this.$route.path == '/tai-khoan/' || this.$route.path == '/tai-khoan';
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === '/tai-khoan/nap-tien/') {
        this.refreshBalances();
      }
    },
    isLoadBalances(newVal) {
      if (newVal) {
        this.refreshBalances();
        this.$store.commit('setIsLoadBalances', false);
      }
    },

    loggedIn() {
      return this.$store.getters.getterLoggedIn;
    }
  }
};
</script>
<style lang="scss">
@import '../assets/sass/profile';
</style>
