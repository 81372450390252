<template>
  <div>
    <div class="back pointer d-flex">
      <div class="leftContainer">
        <button @click="backRouter()" class="headerBtn p-2 pr-3">
          <!-- <img class="icon" src="../../../assets/img/icon/icon_arrow_left.svg" /> -->
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 492 492"
            style="enable-background: new 0 0 492 492"
            xml:space="preserve"
            width="16px"
            height="16px"
            fill="#0d234a"
          >
            <g>
              <g>
                <path
                  d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
      C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
      c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
      l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
                />
              </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
          </svg>
        </button>
        <span class="headerText" v-if="!ticket.ticketId"
          >{{ name }}
          <!-- <router-link :to="tutorialGame" class="d-inline-flex align-items-center ml-2" v-if="tutorialGame"> -->
          <div
            v-if="tutorialGame"
            class="d-inline-flex align-items-center ml-2 pointer"
            @click="$refs.modalTutorialGame.handleOpenModalTutorial(true)"
          >
            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 16 16">
              <path
                d="M-4.8-11.172a8,8,0,0,0-8,8,8,8,0,0,0,8,8,8,8,0,0,0,8-8A8,8,0,0,0-4.8-11.172Zm0,14.452a6.448,6.448,0,0,1-6.452-6.452A6.449,6.449,0,0,1-4.8-9.623,6.449,6.449,0,0,1,1.655-3.172,6.448,6.448,0,0,1-4.8,3.28Zm3.459-8.232A3.2,3.2,0,0,0-4.61-7.817,3.626,3.626,0,0,0-7.854-6.069a.388.388,0,0,0,.085.528l.9.681a.387.387,0,0,0,.538-.069c.485-.613.831-.967,1.579-.967.574,0,1.283.369,1.283.926,0,.421-.347.637-.914.954-.661.37-1.535.831-1.535,1.984v.279a.387.387,0,0,0,.387.387h1.472a.387.387,0,0,0,.387-.387v-.2C-3.674-2.756-1.337-2.79-1.337-4.952ZM-3.442.441A1.356,1.356,0,0,0-4.8-.914,1.356,1.356,0,0,0-6.152.441,1.356,1.356,0,0,0-4.8,1.8,1.356,1.356,0,0,0-3.442.441Z"
                transform="translate(12.797 11.172)"
                fill="#00509d"
              />
            </svg>
            <span class="text-uppercase color-blue font-13"> hướng dẫn </span>
          </div>
          <!-- </router-link> -->
        </span>
      </div>
      <span class="headerText" v-if="!!ticket.ticketId">{{
        ticket.currentRouteName === 'Đơn mua'
          ? 'Đơn mua #' + ticket.ticketId
          : ticket.currentRouteName === 'don-mua-chung'
          ? 'Nhóm mua chung #' + ticket.ticketId
          : name
      }}</span>
      <ModalTutorialGame v-if="tutorialGame" ref="modalTutorialGame" :tutorialGame="tutorialGame" />
    </div>
  </div>
</template>
<script>
import ModalTutorialGame from '../Game/ModalTutorialGame';

export default {
  components: { ModalTutorialGame },
  props: {
    name: {
      type: String
    },
    ticket: {
      type: Object,
      default: () => {
        return {
          orderId: undefined,
          currentRouteName: '',
          ticketId: undefined
        };
      }
    },
    tutorialGame: {
      type: String
    }
  },
  data() {
    return {
      listPath: ['/luat-choi/:slug', '/nap-rut-tien/:slug', '/ho-tro/:slug']
    };
  },
  created() {},
  computed: {
    historyFlagLength: {
      get() {
        return this.$store.getters.getterSaveHistoryFlagLength;
      },
      set() {
        this.$store.commit('setSaveHistoryFlagLength', null);
      }
    }
  },
  methods: {
    backRouter() {
      if (this.$route.meta.isBarcodeResultMode) {
        if (window.ReactNativeWebView) {
          this.sendMessageToApp(JSON.stringify({ event: 'REQUEST_SCANNER' }));
        }
        this.$router.back();
        return;
      }
      //isRecall = false
      // if (this.$route.meta.customRedirectBack === 'order_detail') {
      //   let defaultRoute = `/tai-khoan/lich-su-dat-mua/${this.orderId || ''}/${this.$route.params.periodNo || ''}`;
      //   this.$router.push(defaultRoute);
      //   return;
      // }
      // trường hợp back của trang article
      if (this.$route.matched[1] && this.listPath.includes(this.$route.matched[1].path)) {
        this.$router.go(-1);
        return;
      }
      // if (this.$route.matched[0] && this.$route.matched[0].path == '/ho-tro') {
      //   this.$router.push('/');
      //   return;
      // }
      // if (window.history && (window.history.length == 0 || window.history.length === 1)) {
      //   alert('here');
      //   this.$router.replace('/');
      //   return;
      // }

      if (this.historyFlagLength === window.history.length && this.historyFlagLength < 50) {
        this.$router.replace('/');
        return;
      }
      this.historyFlagLength = null;
      this.$router.go(-1);
      // if (this.$store.getter.getterHistoryResult) {
      //   this.$router.push('/ho-tro');
      //   return;
      // }
      // if (this.$router.currentRoute.fullPath.includes('thong-ke')) {
      //   this.$router.go(-1);
      //   // setTimeout(() => {
      //   //   this.backRouter(true);
      //   // });
      // } else if (!isRecall) {
      //   this.$router.go(-1);
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.headerBtn {
  border: 0;
  background-color: transparent;
  // width: 30px;
  // height: 30px;
  margin-left: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerText {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
}
.leftContainer {
  display: flex;
  justify-content: center;
  // margin-left: 0.375rem;
  flex-direction: row;
  .icon {
    width: 12px;
    height: 12px;
    border: none;
  }
}
</style>
