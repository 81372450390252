<template>
  <div>
    <header-desktop :class="{ 'd-none': !isRoot, 'd-lg-block': true, 'd-block': isRoot }"></header-desktop>
    <header-mobile v-if="!isRoot" class="d-block d-lg-none"></header-mobile>
    <main
      class="layoutSubMain"
      :class="{
        pt40: isPlayTrialModeMobile,
        marginB: marginB
      }"
    >
      <slot />
    </main>
    <!-- <modal-register /> -->
    <Footer class="d-none d-lg-block"></Footer>
  </div>
</template>
<script>
import HeaderDesktop from '../components/HeaderDesktop';
import HeaderMobile from '../components/HeaderMobile';
import Footer from '../components/Footer';
// import ModalRegister from "../components/Account/ModalRegister";
// import NavbarMobile from '../components/NavbarMobile';

export default {
  name: 'app',
  props: {
    marginB: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  components: {
    HeaderDesktop,
    Footer,
    HeaderMobile
    // ModalRegister,
  },
  computed: {
    isRoot() {
      return this.$route.path == '/tai-khoan/' || this.$route.path == '/truc-tiep';
      // return true;
    }
  }
};
</script>
<style lang="scss">
// @import "../assets/sass/main";
.marginB {
  margin-bottom: 140px;
}
</style>
