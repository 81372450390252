<template>
  <div
    class="profileSummary"
    :class="{
      'profile-summary': true,
      'd-none': !isRoot(),
      'd-lg-block': true
    }"
  >
    <div class="boxProfileSummary">
      <div class="profile-info">
        <b-avatar :size="76" class="profileSummaryAvatar">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g transform="translate(-157 -456)">
              <rect width="20" height="20" transform="translate(157 456)" fill="#dedede" opacity="0" />
              <path
                d="M0-6.75a4.5,4.5,0,0,0,4.5-4.5A4.5,4.5,0,0,0,0-15.75a4.5,4.5,0,0,0-4.5,4.5A4.5,4.5,0,0,0,0-6.75ZM3.15-5.625H2.563A6.127,6.127,0,0,1,0-5.062a6.139,6.139,0,0,1-2.563-.562H-3.15A4.726,4.726,0,0,0-7.875-.9V.562A1.688,1.688,0,0,0-6.188,2.25H6.187A1.688,1.688,0,0,0,7.875.562V-.9A4.726,4.726,0,0,0,3.15-5.625Z"
                transform="translate(167 473)"
                fill="#0d234a"
              />
            </g>
          </svg>
        </b-avatar>
        <p class="user-name">{{ getUserName }}</p>
        <p v-if="censorStatus.phone" class="user-phone">
          <span>{{ censorData(getPhone) }}</span
          ><img
            src="@/assets/img/icon/icon_showpass_on.svg"
            alt=""
            class="icon-censor"
            @click="toggleCensorStatus('phone', false)"
          />
        </p>
        <div v-else class="user-phone">
          <span>{{ getPhone }}</span>
          <img
            src="@/assets/img/icon/icon_showpass_off.svg"
            alt=""
            class="icon-censor"
            @click="toggleCensorStatus('phone', true)"
          />
        </div>
      </div>
      <div class="wallet-info">
        <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.DEPOSIT_BALANCE">
          <div class="wallet-section">
            <b-row class="mx-0 align-items-center">
              <b-col class="x-0">
                <div class="wallet-title">TÀI KHOẢN MUA VÉ</div>
                <div class="wallet-detail">
                  <div class="wallet-balance">
                    <CensorData
                      dataKey="wallet"
                      :data="formatPrice(depositBalance + pendingBalance, ' đ')"
                      :showLength="1"
                      :fixedLength="6"
                    />
                  </div>
                </div>
              </b-col>
              <button class="wallet-btn btn-deposit btn" @click="customRedirect('/tai-khoan/nap-tien/')">
                NẠP
              </button>
            </b-row>
          </div>
        </ExcludeFeatureWrapper>
        <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.REWARD_BALANCE">
          <div class="wallet-section mt-2">
            <b-row class="mx-0 align-items-center">
              <b-col class="x-0">
                <div class="wallet-title">TÀI KHOẢN THƯỞNG</div>
                <div class="wallet-detail">
                  <div class="wallet-balance">
                    <CensorData
                      dataKey="wallet"
                      :data="formatPrice(rewardBalance, ' đ')"
                      :showLength="1"
                      :fixedLength="6"
                    />
                  </div>
                </div>
              </b-col>
              <button class="wallet-btn btn-withdraw btn btn-light" @click="handleWithdraw">RÚT</button>
            </b-row>
          </div>
        </ExcludeFeatureWrapper>
      </div>
      <div class="navigate-tabs mt-0">
        <!-- <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.USER_INFO">
          <button
            :class="{
              'summary-btn': true,
              btn: true,
              'btn-light': true,
              'btn-active': isActive('profile')
            }"
            @click="customRedirect('/tai-khoan/chi-tiet/')"
          >
            <div class="btn-icon customBtnIcon">
              <img src="@/assets/img/icon/icon_acc_profile.svg" alt />
            </div>
            <div class="btn-text">CHI TIẾT TÀI KHOẢN</div>
          </button>
        </ExcludeFeatureWrapper> -->

        <!-- <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.SUMMARY">
          <button
            :class="{
              'summary-btn': true,
              btn: true,
              'btn-light': true,
              'btn-active': isActive('shop')
            }"
            @click="customRedirect('/tai-khoan/bao-cao/')"
            v-if="vipInfo"
          >
            <div class="btn-icon customBtnIcon">
              <img src="@/assets/img/icon/pie-chart.svg" alt />
            </div>
            <div class="btn-text">BÁO CÁO</div>
          </button>
        </ExcludeFeatureWrapper> -->

        <button
          :class="{
            'summary-btn': true,
            btn: true,
            'btn-light': true,
            'btn-active': isActive('order')
          }"
          @click="customRedirect('/tai-khoan/lich-su-dat-mua/')"
        >
          <div class="btn-icon customBtnIcon">
            <img src="@/assets/img/icon/icon_acc_tickets.svg" alt />
          </div>
          <div class="btn-text">VÉ ĐÃ MUA</div>
        </button>
        <!-- <ExcludeFeatureWrapper v-if="!this.isPaymentIntegration" :featureName="FEATURE_HANDLER.BUY_GROUP">
          <button
            :class="{
              'summary-btn': true,
              btn: true,
              'btn-light': true,
              'btn-active': isActive('together-order')
            }"
            @click="customRedirect('/tai-khoan/lich-su-dat-mua-chung/')"
          >
            <div class="btn-icon customBtnIcon">
              <img src="@/assets/img/icon/icon_acc_tickets.svg" alt />
            </div>
            <div class="btn-text">VÉ MUA CHUNG</div>
          </button>
        </ExcludeFeatureWrapper> -->

        <!-- <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.GIFT_TRANSACTION">
          <button
            :class="{
              'summary-btn': true,
              btn: true,
              'btn-light': true,
              'btn-active': isActive('gift-order')
            }"
            @click="customRedirect('/tai-khoan/ve-da-tang/')"
          >
            <div class="btn-icon customBtnIcon">
              <img src="@/assets/img/icon/icon_vedatang.svg" alt />
            </div>
            <div class="btn-text">VÉ ĐÃ TẶNG</div>
          </button>
        </ExcludeFeatureWrapper> -->
        <!-- <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.GIFTED_TRANSACTION">
          <button
            :class="{
              'summary-btn': true,
              btn: true,
              'btn-light': true,
              'btn-active': isActive('gifted-order')
            }"
            @click="customRedirect('/tai-khoan/ve-duoc-tang/')"
          >
            <div class="btn-icon customBtnIcon">
              <img src="@/assets/img/icon/icon_veduoctang.svg" alt />
            </div>
            <div class="btn-text">VÉ ĐƯỢC TẶNG</div>
          </button>
        </ExcludeFeatureWrapper> -->

        <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.TRANSACTION_HISTORY">
          <button
            :class="{
              'summary-btn': true,
              btn: true,
              'btn-light': true,
              'btn-active': isActive('history')
            }"
            @click="customRedirect('/tai-khoan/lich-su-giao-dich/')"
          >
            <div class="btn-icon customBtnIcon">
              <img src="@/assets/img/icon/icon_acc_tx-history.svg" alt />
            </div>
            <div class="btn-text text-uppercase">Lịch sử giao dịch</div>
          </button>
        </ExcludeFeatureWrapper>
        <!-- <button
          :class="{
            'summary-btn': true,
            btn: true,
            'btn-light': true,
            'btn-active': isActive('fav')
          }"
          @click="customRedirect('/tai-khoan/bo-so-yeu-thich/')"
        >
          <div class="btn-icon customBtnIcon">
            <img src="@/assets/img/icon/icon_acc_fav.svg" alt />
          </div>
          <div class="btn-text">BỘ SỐ YÊU THÍCH</div>
        </button> -->

        <!-- <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.INVITE_FRIEND">
          <button
            :class="{
              'summary-btn': true,
              btn: true,
              'btn-light': true,
              'btn-active': isActive('invite_friend')
            }"
            @click="customRedirect('/tai-khoan/gioi-thieu-ban/')"
          >
            <div class="btn-icon customBtnIcon">
              <img src="@/assets/img/icon/icon_invite-menu.svg" alt />
            </div>
            <div class="btn-text">GIỚI THIỆU BẠN</div>
          </button>
        </ExcludeFeatureWrapper> -->

        <button
          :class="{
            'summary-btn': true,
            btn: true,
            'btn-light': true,
            'btn-active': isActive('notification')
          }"
          @click="customRedirect('/tai-khoan/thong-bao')"
        >
          <div class="btn-icon customBtnIcon">
            <img src="@/assets/img/icon/icon_acc_notify.svg" alt />
          </div>
          <div class="btn-text">THÔNG BÁO</div>
        </button>
      </div>
      <ExcludeFeatureWrapper :featureName="FEATURE_HANDLER.LOGOUT">
        <hr class="spliter" v-if="!isWeluckMode" />
        <div class="logout-btn" v-if="!isWeluckMode">
          <button class="summary-btn btn btn-light" @click="logout">
            <div class="btn-icon customBtnIcon">
              <img src="@/assets/img/icon/icon_acc_logout.svg" alt />
            </div>
            <div class="btn-text">ĐĂNG XUẤT</div>
          </button>
        </div>
      </ExcludeFeatureWrapper>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import { eventTypeMessage } from '../../../common/global';
import CensorData from '../../CensorData';
import ExcludeFeatureWrapper from '../../ExcludeFeatureWrapper.vue';
export default {
  layouts: 'ProfileSummary',
  components: {
    CensorData,
    ExcludeFeatureWrapper
  },
  data() {
    return {
      minWithdraw: 10000,
      censorStatus: {
        deposit: true,
        reward: true,
        phone: true
      }
    };
  },

  computed: {
    currentPath: function() {
      return this.$route.path;
    },
    getUserName() {
      return this.$store.getters.getterUserInfo.name;
    },
    getPhone() {
      return this.$store.getters.getterUserInfo.phone;
    },
    historyFlagLength: {
      get() {
        return this.$store.getters.getterSaveHistoryFlagLength;
      },
      set() {
        this.$store.commit('setSaveHistoryFlagLength', null);
      }
    },
    vipInfo() {
      return this.$store.getters.getterUserInfo.vip_data;
    }
  },
  methods: {
    toggleCensorStatus(key, value) {
      this.censorStatus = { ...this.censorStatus, [key]: value };
    },
    logout() {
      this.$store.dispatch('destroyToken').then(() => {
        this.sendMessageToParent(eventTypeMessage.SIGN_OUT);
      });
    },
    errSolve(err) {
      if (
        err.name !== 'NavigationDuplicated' &&
        !err.message.includes('Avoided redundant navigation to current location')
      ) {
        // But print any other errors to the console
      }
    },
    isActive(path) {
      return path == this.$route.meta.root;
    },
    isRoot() {
      return this.currentPath == '/tai-khoan/' || this.currentPath == '/tai-khoan';
    },
    customRedirect(path) {
      if (this.historyFlagLength) this.historyFlagLength = null;
      this.$router.push(path).catch(this.errSolve);
    },
    handleWithdraw() {
      if (this.rewardBalance >= this.minWithdraw) this.customRedirect('/tai-khoan/rut-tien/');
      else
        Swal.fire({
          icon: 'info',
          title: `Bạn cần có tối thiểu ${this.formatPrice(
            this.minWithdraw
          )} trong tài khoản thưởng để thực hiện rút tiền.`,
          showClass: {
            popup: 'swal2-noanimation',
            backdrop: 'swal2-noanimation'
          },
          confirmButtonColor: '#d31904',
          showConfirmButton: false,
          customClass: {
            title: 'sweetTitle',
            cancelButton: 'confirm-button-class'
          },
          timer: 2000
        });
    }
  }
};
</script>

<style lang="scss">
.profile-summary {
  .customBtnIcon.customBtnIconNotify {
    padding-top: 5px;
  }
  .customBtnIcon {
    img {
      vertical-align: -webkit-baseline-middle;
    }
  }
  .spliter {
    padding: 0 9px;
    margin-top: 9px;
    margin-bottom: 9px;
  }
}
@media (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .profile-summary .summary-btn.btn-active {
    background-color: white !important;
  }
  .profile-summary {
    width: 100% !important;
  }
}
</style>
